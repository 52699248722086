import Cookies from "js-cookie";

const TokenKey = "www_aiopay001_com_cashier_key";

function getSysKey(key) { 
    return TokenKey + "_" + key;
}
export function setByKey(key, value) {
    return Cookies.set(getSysKey(key), value);
}
export function getByKey(key) {
    return Cookies.get(getSysKey(key));
}

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}

export function getName() {
    return Cookies.get(TokenKey + "_name");
}

export function setName(value) {
    return Cookies.set(TokenKey + "_name", value);
}



export function setPayOrderData(value) {
    return Cookies.set(TokenKey + "_pay_orderinfo", JSON.stringify(value));
}

export function getPayOrderData(key) {
    let value = Cookies.get(TokenKey + "_pay_orderinfo");
    if (value === undefined) { 
        return {}
    }
    let user = {}
    if (value != "") {
        user = JSON.parse(value);
    }
    if (key) {
        return typeof user[key] !== undefined ? user[key] : "";
    }
    return user;
}

export function setData(value) {
    return Cookies.set(TokenKey + "_data", JSON.stringify(value));
}

export function getData(key) {
    let value = Cookies.get(TokenKey + "_data");
    let user = []
    if (value != "") {
        user = JSON.parse(value);
    }
    if (key) {
        return typeof user[key] !== undefined ? user[key] : "";
    }
    return user;
}
