<template>
    <div></div>

</template>


<script>
import _validate from "@/utils/validate";
import * as _cookie from '@/utils/cookie'
import {
    getPayOrderInfoByPayOrderId,
    submitUnifiedOrder
} from '@/api/api'

export default {
    props: {
        orderId: {
            typeof: String,
            default: null
        }
    },
    data() {
        return {
            amount: "0",
            timerId: null,

        }
    },
    created() {
        this.getPaymentOrderData();

    },
    mounted() {

    },
    methods: {

        getPaymentOrderData() {
            const _this = this;
            console.log(`output->this.timerId `, this.timerId)

            getPayOrderInfoByPayOrderId(this.orderId).then(res => {

                //清理定时器
                let channelExtra = JSON.parse(res.channelExtra);
                if (parseInt(res.state) === 2) {
                    clearInterval(_this.timerId);
                    alert('Payed Success');
                    _cookie.setData({
                        orderId: res.payOrderId,
                        virAccountNo: res.channelOrderNo,
                        amt: res.amt,
                        returnUrl: res.returnUrl,
                        paymentName: channelExtra.name
                    });

                    return _this.$router.push("/cashier/rp/paysucced");
                }

                if (parseInt(res.state) === 6) { 
                    throw new Error("gagal")
                }

                setTimeout(() => {
                    this.getPaymentOrderData();
                }, 3000)
            }).catch(err => { 
                // alert("Memuat isi gagal");
                return _this.$router.push(`/cashier/rp/payfail?tn=${ _this.orderId }`);
            })

        },
        copyNum() {
            //拷贝数字
        },
        changeTabIndex(index) {
            this.tabIndex = index;
        },
        toQris() {
            const _this = this;
            submitUnifiedOrder(this.orderId).then(res => {
                _this.wayCode = res.wayCode
                _this.qrCodeUrl = res.qrCodeUrl;

                //临时存储内容
                _cookie.setData({
                    qrCodeUrl: this.qrCodeUrl,
                    orderId: this.orderId,
                    amt: this.amount,
                });

                setTimeout(() => {
                    location.href = '/cashier/qrcodedetail'
                }, 200)

                //临时存储虚拟账户
            }).catch(error => {
                alert('permintaan dibatalkan[' + error + ']');
            })
            //请求二维码内容

            // location.href = '/cashier/qrcodedetail'
        },
        toBank(record) {
            if (this.amount <= 0) {
                return;
            }
            //临时存储虚拟账户
            // this.virAccountNo = "4041910113217624";
            // //临时存储内容
            // _cookie.setData({
            //     virAccountNo: this.virAccountNo,
            //     orderId: this.orderId,
            //     amt: this.amount,
            //     bank: record
            // });

            // setTimeout(() => { 
            //     location.href = '/cashier/bankdetail'
            // }, 200)
            submitUnifiedOrder(this.orderId, { bank: record.bank_code }).then(res => {
                console.log(`output->res`, res)
                //临时存储虚拟账户
                this.virAccountNo = res.virAccountNo;
                //临时存储内容
                _cookie.setData({
                    virAccountNo: this.virAccountNo,
                    orderId: this.orderId,
                    amt: this.amount,
                    bank: record
                });

                setTimeout(() => {
                    location.href = '/cashier/bankdetail'
                }, 200)
            }).catch(error => {
                alert('permintaan dibatalkan[' + error + ']');
            })

        }
    }

}
</script>
